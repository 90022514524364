import React, { useState } from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
// import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { CloudArrowUpIcon, ArrowsRightLeftIcon, BanknotesIcon, UsersIcon } from '@heroicons/react/24/outline'
import { StarIcon, CheckBadgeIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import EcomNav from "../components/ecomNav"
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import SEO from "../components/seo/seo-new"

import 'swiper/css/bundle'

import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';


export const accountingQuery = graphql`
query MyQueryAccounting($accountingPlatform: String, $ecomsUID: String, $ecommercePlatform: String, $language: String!) {
  prismicEcommerce(data: {ecommerce_name: {text: {eq: $ecommercePlatform}}}) {
    uid
    data {
      status
      ecommerce_name {
        text
      }
      logo {
        alt
        url
      }
      color
      sign_up_link {
        url
      }
      hero_image {
        url
      }
      logo_white_svg {
        url
      }
      logo_white_height
      text_color
      accounting_integration1 {
        accounting_integration {
          document {
            ... on PrismicAccounting {
              data {
                short_name
                icon {
                  url
                }
                 logo {
                  url
                }
                long_name
              }
              uid
            }
          }
        }
      }
    }
  }
    allPrismicCompetitors(filter: {data: {ecom: {elemMatch: {ecom_uid: {eq: $ecomsUID}}}}}) {
            nodes {
                data {
                    name
                }
                uid
            }
        }
  prismicAccounting(uid: {eq: $accountingPlatform}) {
    data {
      long_name
      short_name
      icon {
        alt
        url
      }
      logo {
        alt
        url
      }
      hero {
        url
      }
      graphic {
        url
      }
      title
      bullet {
        bullet1
      }
      sign_up_link {
        url
      }
    }
  }
  allPrismicEcommerce {
    nodes {
      data {
        marketplaces {
          marketplace_name
          marketplace_uid
        }
      }
    }
  }
  allPrismicTestimonial (
    filter: {data: {account: {elemMatch: {accounting: {uid: {eq: $accountingPlatform }}}}, ecom: {elemMatch: {ecommerce: {uid: {eq: $ecomsUID}}}}}}
      ){
    nodes {
      data {
        testimonial_text {
          html
        }
        type
        seller_sales_amount
        seller_type
        review_location
        name
        avatar {
          url
        }
        account {
          accounting {
            uid
          }
        }
        ecom {
          ecommerce {
            uid
          }
        }
      }

    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

}
`


export default function ConnectQBO({ data, pageContext }) {
  const lang = data.locales.edges[0].node.language
  const { t } = useTranslation();

  const ecom = data.prismicEcommerce.data
  const ecomName = pageContext.ecommerceName
  const ecommercePlatform = pageContext.ecommercePlatform
  const ecomUID = pageContext.ecommerceUID
  const ecomsUID = pageContext.ecomsUID
  const ecomColor = ecom.color
  const ecomTextColor = ecom.text_color
  const ecomStatus = ecom.status
  const accounting = ecom.accounting_integration1

  const acc = data.prismicAccounting.data
  const accLongName = acc.long_name
  const accShortName = acc.short_name
  const accHero = acc.hero.url
  const bullets = acc.bullet
  const accTitle = acc.title
  const accGraphic = acc.graphic.url
  const accSignUp = acc.sign_up_link.url

  const testimonials = data.allPrismicTestimonial.nodes


  const register = ecom.sign_up_link.url
  const logoWhite = ecom.logo_white_svg.url
  const logoWhiteSize = ecom.logo_white_height

  const amzMarketplace = ecom.ecommerce_name.text
  const allMarketplaces = data.allPrismicEcommerce.nodes
  const allPrismicCompetitors = data.allPrismicCompetitors.nodes
  const competitorCheck = allPrismicCompetitors[0]

  const description = t('Connect') + ` ${ecomName} ` + t('to') + ` ${accShortName} ` + t('with our quick, easy, and automatic') + ` ${accShortName} ` + ` ${ecomName} ` + t('Integration Software. Best') + ` ${ecomName} ${accShortName} ` + t('integration software.')
  const title = `${ecomName} ${accShortName} ` + t('Integration Software')

  const [change, setChange] = useState(false);
  let url;
  lang == "en" ?
    url = `/${pageContext.page}/`
    :
    url = `/${lang}/${pageContext.page}/`
  const originalPathname = `/${pageContext.page}/`

  return (
    <Layout>

      <SEO
        title={title}
        description={description}
        image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
        pathname={url}
        originalPathname={originalPathname}
        lang={lang}
        alternate
      />



      <EcomNav
        ecomUID={`${ecomUID}`}
        ecomName={`${ecomName}`}
        ecomColor={`${ecomColor}`}
        logoWhite={`${logoWhite}`}
        logoWhiteSize={`${logoWhiteSize}`}
        marketplace={`${amzMarketplace}`}
        ecommercePlatform={`${ecommercePlatform}`}
        allMarketplaces={allMarketplaces}
        accounting={accounting}
        allCompetitors={allPrismicCompetitors}
        ecomsUID={ecomsUID}
        competitorCheck={competitorCheck}
      />


      <section className="mx-auto max-w-md px-2 sm:max-w-7xl sm:px-6 lg:px-8 lg:max-w-8xl lg:grid lg:grid-cols-2 lg:gap-6 py-20">

        <div className="pt-20 block px-10">

          <h1 className="font text-gray-600 font-bold text-3xl sm:text-5xl leading-normal">
            {ecomName} <span className={`text-${ecomTextColor}`}>{accShortName}</span> <Trans>Integration</Trans>
          </h1>

          {/* <h2>{ecomName} accounting made easy</h2> */}
{/*
          <p className="mt-6 text-gray-500">
            {ecomStatus === "Coming Soon"
              ? t('Signup to be notified when we launch our') + ` ${ecomName} ` + t('integration with') + ` ${accLongName}` + '.'

              : t('taxomate automates bookkeeping by importing') + ` ${ecomName} ` + t('payouts into') + ` ${accLongName}` + t(' so you can focus on the important things.')

            }
          </p> */}


          <ul class="list-none pt-5">
            <li class="flex items-center mb-2">
              <div className="h-8 w-8">
                <CheckCircleIcon className="h-8 w-8 fill-lime-50 text-green-400 drop-shadow-[0_5px_5px_rgba(217,249,157,1)] pr-3"></CheckCircleIcon>
              </div>
              <span class="text-sm"> <Trans>Save time and money by automatically integrating Amazon with {accShortName}.</Trans></span>
            </li>
            <li class="flex items-center mb-2">
              <div className="h-8 w-8">
                <CheckCircleIcon className="h-8 w-8 fill-lime-50 text-green-400 drop-shadow-[0_5px_5px_rgba(217,249,157,1)] pr-3"></CheckCircleIcon>
              </div>
              <span class="text-sm"> <Trans>Grow your business by understanding your businesses profitability.</Trans></span>
            </li>
            <li class="flex items-center mb-2">
              <div className="h-8 w-8">
                <CheckCircleIcon className="h-8 w-8 fill-lime-50 text-green-400 drop-shadow-[0_5px_5px_rgba(217,249,157,1)] pr-3"></CheckCircleIcon>
              </div>
              <span class="text-sm"> <Trans>Make tax time easier. Your accountant will thank you.</Trans></span>
            </li>
          </ul>


          <div className="mt-7">
            <a
              href={`${register}`}
              className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              {ecomStatus === "Coming Soon"
                ? t('Notify When Ready')
                : t('Try For Free')
              }
            </a>
          </div>
          <div className="pt-3 pl-1">

            <p className="text-[11px]">
              {ecomStatus === "Coming Soon"
                ? 'Signup to be notified when we launch our ' + `${ecomName}` + ' integration.'

                : 'Start Your 14 Day Free Trial'
              }
            </p>
          </div>

          <div className="mt-5">
            <div className="inline-flex items-center divide-x divide-gray-300">
              <div className="shrink-0 flex pr-5">
                <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                <span className="font-medium text-gray-900"><Trans>Rated 5 stars</Trans></span> by{' '}
                <Link to="/reviews">
                  <span className="font-medium text-blue-600"><Trans>hundreds of {ecomName} Sellers</Trans></span>
                </Link>
              </div>
            </div>
          </div>


        </div>



        <div >
          <img
            src={accHero}
            className="w-full"
            alt={`Connect ${ecomName} to ${accLongName}`}
            placeholder="blurred"
          />

        </div>
      </section>



      <section className="bg-gray-50 overflow-hidden py-20">
        <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <svg
            className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
          </svg>

          <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
            <div className="lg:col-span-1">
              <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Why choose taxomate?
              </h2>
            </div>
            <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
              <div>
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <CloudArrowUpIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900"><Trans>Easily Reconcile and Record</Trans> {ecomName} <Trans>Sales in</Trans> {accShortName}</p>
                </dt>
                <dd className="mt-2 text-base text-gray-500"><Trans>Takes the pain away from manually matching sales against the lump sum deposited by</Trans> {ecomName}.</dd>
              </div>
              <div>
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <BanknotesIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900"><Trans>Save Time and Money</Trans></p>
                </dt>
                <dd className="mt-2 text-base text-gray-500"><Trans>Posting</Trans> {ecomName} <Trans>expenses, sales, refunds, and other fees in</Trans> {accShortName} <Trans>for quick and easy reconciliation.</Trans></dd>
              </div>
              <div>
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <ArrowsRightLeftIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900"><Trans>Auto Sync</Trans> {ecomName} <Trans>with</Trans> {accShortName}</p>
                </dt>
                <dd className="mt-2 text-base text-gray-500"><Trans>Turn on auto sync to automatically import and send your settlements to</Trans> {accShortName} <Trans>when they are available.</Trans></dd>
              </div>
              <div>
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <UsersIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900"><Trans>Incredible Support</Trans></p>
                </dt>
            <dd className="mt-2 text-base text-gray-500"><Trans>With 1:1 onboarding and support at no extra cost and our step-by-step guides, it is easy to get started with taxomate in minutes so you can save hours each month.</Trans></dd>
              </div>

            </dl>
          </div>
        </div>
      </section>




      {/* <section className="py-20 bg-blue-50 radius-for-skew">
        <h2 className="text-2xl text-gray-900 tracking-tight sm:text-5xl pb-10 justify justify-center text-center">
          <Trans>{ecomName} Sellers</Trans> <span>❤️</span> <Trans>taxomate</Trans>
        </h2>

        <div className="justify justify-center text-center">
          <div className="inline-flex items-center divide-x divide-gray-300">
            <div className="shrink-0 flex pr-5">
              <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
              <span className="font-medium text-gray-900"><Trans>100+ 5 Star</Trans></span> <Trans>Reviews</Trans> </div>
          </div>
        </div>

        <div className="py-20 ed">
          <Swiper
            breakpoints={{
              640: {
                width: 640,
                slidesPerView: 1,
              },
              768: {
                width: 1400,
                slidesPerView: 2,
              },
            }}
            centeredSlides={true}
            spaceBetween={30}
            grabCursor={true}
            autoHeight={true}

            autoplay={{ delay: 10000 }}
            pagination={{ clickable: true }}
            loop
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            modules={[Navigation, Pagination, Autoplay]}

          >
            {testimonials.map((testimonial) => (


              <SwiperSlide>
                <div className="container mx-auto px-4">
                  <div className="mx-auto py-3 bg-white shadow rounded-lg">
                    <div className="max-w-4xl p-4 flex flex-wrap justify-center items-center">
                      <div className="mb-6 w-full lg:w-1/3 text-center align-middle">
                        <img
                          className="mb-6 mx-auto w-20 h-20 rounded-full object-cover"
                          src={testimonial.data.avatar.url}
                          alt="ecommerce integration service"
                        />
                        <h4 className="text-lg">{testimonial.data.name}</h4>
                        <p className="text-blue-600">{testimonial.data.seller_sales_amount}</p>
                      </div>
                      <div className="w-full lg:w-2/3">
                        <div className="hrink-0 flex pr-5 py-2">
                          <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>
                        <h4 className="mb-6 text-md font-heading">
                          <div dangerouslySetInnerHTML={{ __html: testimonial.data.testimonial_text.html }} />

                        </h4>
                        <div className="inline-flex items-center text-sm font-bold text-blue-600">

                          <CheckBadgeIcon className="h-9 w-9 px-2" />
                          {testimonial.data.review_location === "QuickBooks" ? `Verified — QuickBooks App Store` : ``}
                          {testimonial.data.review_location === "Xero" ? `Verified — Xero App Store` : ``}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

            ))
            }
          </Swiper>
          <div className="text-center py-6">
            <Link
              to="/reviews"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-transparent rounded-md shadow border-2 text-base font-medium text-white bg-blue-600 hover:bg-white hover:text-blue-600"
            >
            <Trans>See More Reviews</Trans>
              <div className="px-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </div>
            </Link>
          </div>
        </div>


      </section> */}

      {/* <section className="relative bg-gray-800">
        <div className="h-56 bg-white sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img
            className="w-full h-full object-cover"
            src={accGraphic}
            alt={`What is ${accShortName} ${ecomName} Integration?`}
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div className="md:ml-auto md:w-1/2 md:pl-10">
            <h2 className="text-2xl uppercase tracking-wider text-gray-300">{accTitle}</h2>
            <p className="mt-3 text-lg text-gray-300">

              <ul class="list-disc pl-3">
                {bullets.map((bullet) => (

                  <li>
                    {bullet.bullet1}
                  </li>
                ))}

              </ul>
            </p>
            <div className="mt-8">
              <div className="inline-flex rounded-md shadow">
                <a
                  href={accSignUp}
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                >
                <Trans>Try for Free</Trans>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="bg-blue-50 py-20">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="bg-blue-600 rounded-lg shadow border-2 overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
            <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
              <div className="lg:self-center">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                  <span className="block">
                    {ecomStatus === "Coming Soon"
                      ? t('Want to Be Notified When We Launch?')
                      : t('Ready to Dive In?')
                    }
                  </span>
                  <span className="block">
                    {ecomStatus === "Coming Soon"
                      ? ''
                      : t('Start your free trial today.')
                    }
                  </span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-indigo-200">
                  {ecomStatus === "Coming Soon"
                    ? t('Sign up to be notified when we are ready to launch')
                    : t('Create an account and start automating your') + ` ${ecomName}` + ' ' + `${accShortName} ` + t('accounting!')}
                </p>
                <a
                  href={`${register}`}
                  className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
                >
                  {ecomStatus === "Coming Soon"
                    ? t('Notify When Ready')
                    : t('Sign up for free')
                  }
                </a>
              </div>
            </div>
            <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
              <img
                className="transform translate-x-16 translate-y-20 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                src={ecom.hero_image.url}
                alt={`Connect ${ecomName} to ${accShortName}`}
                placeholder="blurred"
              />
            </div>
          </div>
        </div>
      </section>





    </Layout>
  );
}
